import {TemplateInstance, propertyIdentityOrBooleanAttribute} from '@github/template-parts'
import {TextScore, compare, fuzzyScore} from '../../../assets/modules/github/fuzzy-filter'
import {controller, target} from '@github/catalyst'

import VirtualFilterInputElement from '../../../assets/modules/github/virtual-filter-input-element'
import VirtualListElement from '../../../assets/modules/github/virtual-list-element'
import {requestSubmit} from '../../../assets/modules/github/form'

type ProjectSuggestion = {
  number: number
  name: string
  owner: string
  selected: boolean
}

type SelectionDiff = {
  [number: number]: boolean
}

/**
 * Project picker element used in the repository.
 * Controlls the virtual list and filter input.
 *
 * Key resposibilities:
 * - Rendering: rendering list items, showing loading and empty states
 * - Tracking selection: tracks diff of selected & deselected items, serializing the state for form submission
 * - Syncing list visibility with virtual rendering lifecycle
 */
@controller
class MemexProjectPickerElement extends HTMLElement {
  @target filterElement: VirtualFilterInputElement<ProjectSuggestion>
  @target list: VirtualListElement<ProjectSuggestion>
  @target itemTemplate: HTMLTemplateElement
  @target selectionTemplate: HTMLTemplateElement
  @target submitContainer: HTMLElement
  @target blankslateAllProjects: HTMLElement
  @target blankslateRecentProjects: HTMLElement
  @target all: HTMLElement
  @target filterInput: HTMLInputElement
  @target loadingIndicator: SVGElement

  loading = true
  isAllProjectsMode = false

  recentSrc = ''
  allSrc = ''

  selectionDiff: SelectionDiff = {}

  hashedItemScore = new Map<ProjectSuggestion, TextScore>()

  connectedCallback() {
    this.recentSrc = this.filterElement.src
    this.allSrc = this.all.getAttribute('data-src') || ''

    this.filterElement.filter = (item: ProjectSuggestion, query: string) => {
      if (!query) return true

      // Constructing the hash<item, TextScore> needed for the sorting function
      // during the filter process to avoid doing the score again when filtering is done
      const itemText = `${item.name.trim().toLowerCase()}`
      const score = fuzzyScore(itemText, query)
      const key = score > 0 ? {score, text: itemText} : null
      if (key) this.hashedItemScore.set(item, key)
      return score > 0
    }

    const detailsMenu = this.closest('details.select-menu') as HTMLDetailsElement
    if (detailsMenu && this.list) detailsMenu.addEventListener('toggle', this.toggleProjectMenu.bind(this))

    this.filterElement.addEventListener('virtual-filter-input-filtered', () => this.filterDone())

    this.filterElement.input?.addEventListener('keydown', e => {
      if ((e as KeyboardEvent).key === 'Enter') e.preventDefault()
    })
  }

  filterDone() {
    this.list.sort((a, b) => {
      const itemB = this.hashedItemScore.get(b)
      const itemA = this.hashedItemScore.get(a)

      if (!itemA && !itemB) return 0
      if (!itemA) return 1
      if (!itemB) return -1

      return compare(itemA, itemB)
    })
  }

  switchMode() {
    const hasQuery = this.filterInput.value.length > 0
    if (hasQuery && this.filterElement.src !== this.allSrc) {
      this.switchToAllProjectsMode()
    } else if (!hasQuery && this.filterElement.src !== this.recentSrc) {
      this.switchToRecentProjectMode()
    }
  }

  switchToAllProjectsMode() {
    this.filterElement.src = this.allSrc
    this.filterElement.loading = 'eager'
    this.isAllProjectsMode = true
  }

  switchToRecentProjectMode() {
    this.filterElement.src = this.recentSrc
    this.filterElement.loading = 'eager'
    this.isAllProjectsMode = false
  }

  showAllProjectsBlankslate() {
    this.blankslateAllProjects.hidden = false
  }

  hideAllProjectsBlankslate() {
    this.blankslateAllProjects.hidden = true
  }

  showRecentProjectsBlankslate() {
    this.blankslateRecentProjects.hidden = false
  }

  hideRecentProjectsBlankslate() {
    this.blankslateRecentProjects.hidden = true
  }

  showBlankslate() {
    this.isAllProjectsMode ? this.showAllProjectsBlankslate() : this.showRecentProjectsBlankslate()
  }

  hideBlankslate() {
    this.isAllProjectsMode ? this.hideAllProjectsBlankslate() : this.hideRecentProjectsBlankslate()
  }

  showLoadingIndicator() {
    this.loadingIndicator.removeAttribute('hidden')
  }

  hideLoadingIndicator() {
    this.loadingIndicator.setAttribute('hidden', 'true')
  }

  willLoad() {
    this.loading = true
    this.showLoadingIndicator()
    this.hideAllProjectsBlankslate()
    this.hideRecentProjectsBlankslate()
    this.filterElement.reset()
  }

  didLoad() {
    this.loading = false
    this.hideLoadingIndicator()
  }

  willRenderItems() {
    if (this.loading) return
    if (this.list.size === 0) {
      this.showBlankslate()
    } else {
      this.hideBlankslate()
    }
  }

  renderItem(event: CustomEvent) {
    // append the element to virtual list fragment container
    // this is the way you define what to render
    event.detail.fragment.append(this.createItem(event))
  }

  toggleItem(event: MouseEvent) {
    // handle clicks originated only from a checkbox inside a label
    if (!(event.target instanceof HTMLInputElement) || event.target.type !== 'checkbox') return

    const projectElement = event.target.closest('[data-project-number]')
    if (!projectElement) {
      return
    }

    const projectNumber = parseInt(projectElement.getAttribute('data-project-number') || '', 10)

    const selected = projectElement.getAttribute('aria-checked') || ''
    if (selected === 'true') {
      // Deselecting
      this.selectionDiff[projectNumber] = false
      projectElement.setAttribute('aria-checked', 'false')
    } else {
      // Selecting
      this.selectionDiff[projectNumber] = true
      projectElement.setAttribute('aria-checked', 'true')
    }
    this.serializeSelection()
  }

  private isProjectSelected(suggestion: ProjectSuggestion) {
    return this.selectionDiff[suggestion.number] ?? suggestion.selected
  }

  private toggleProjectMenu(event: Event) {
    if (!event.target) return
    const detailsMenu = event.target as HTMLDetailsElement
    if (detailsMenu.open) return this.onMenuOpen()
    this.onMenuClosed(event)
  }

  private onMenuOpen() {
    // workaround the 0 size of the virtual list in case it's hidden behind the details menu
    this.list.update()

    // request the data again if the dataset was empty
    if (this.loading === false && this.list.size === 0) {
      this.filterElement.load()
    }
  }

  private onMenuClosed(event: Event) {
    // Clear the input when the menu is closed
    this.filterElement.clear()

    // if the details menu is closed, submit the form
    const currentTarget = event.currentTarget as Element
    const form = currentTarget.closest('.js-repo-project-picker-form') as HTMLFormElement

    // Check to see if there are changes before submitting
    for (const el of form.elements) {
      if ((el.getAttribute('name') || '').indexOf('repo_memex_link') > -1) {
        requestSubmit(form)
        return
      }
    }
  }

  private createSubmitSelection(projectNumber: string, selected: boolean) {
    return new TemplateInstance(
      this.selectionTemplate,
      {
        name: `repo_memex_link[${projectNumber.toString()}]`,
        checked: selected,
        type: selected ? 'checkbox' : 'hidden'
      },
      propertyIdentityOrBooleanAttribute
    )
  }

  private serializeSelection() {
    this.submitContainer.replaceChildren()

    for (const [projectNumber, selected] of Object.entries(this.selectionDiff)) {
      this.submitContainer.append(this.createSubmitSelection(projectNumber, selected))
    }
  }

  private createItem(event: CustomEvent) {
    return new TemplateInstance(
      this.itemTemplate,
      {
        number: event.detail.item.number,
        name: event.detail.item.name,
        owner: event.detail.item.owner,
        selected: this.isProjectSelected(event.detail.item)
      },
      propertyIdentityOrBooleanAttribute
    )
  }
}
